// 左右云平台路由
const zyy = [
  // 线索管理
  // {
  //   path: 'clue/putClues',
  //   name: 'putClues',
  //   component: () => import('@v/clue/zyy/putClues.vue'),
  //   meta: {
  //     isThreeNav: true,
  //     title: '投放线索',
  //     activeUrl: '/CPlatform',
  //   },
  // },
  // {
  //   path: 'clue/naturalClues',
  //   name: 'naturalClues',
  //   component: () => import('@v/clue/zyy/naturalClues.vue'),
  //   meta: {
  //     isThreeNav: true,
  //     title: '自然线索',
  //     activeUrl: '/CPlatform',
  //     initNav3:true
  //   },
  // },
  // {
  //   path: 'clue/CInternal',
  //   name: 'CInternal',
  //   component: () => import('@v/clue/zyy/CInternal.vue'),
  //   meta: {
  //     title: '内部消化',
  //     activeUrl: '/clue/CInternal',
  //   },
  // },
  {
    //线索公海的左心房投放线索
    path: "clue/throwClues",
    name: "throwClues",
    component: () => import("@v/clueV3/zyy/throwClues.vue"),
    meta: {
      isThreeNav: true,
      title: "（公海）左心房投放线索",
      activeUrl: "/ptClueOpenSea",
    },
  },
  {
    //线索公海的金蝶投放线索
    path: "clue/KingdeeClues",
    name: "KingdeeClues",
    component: () => import("@v/clueV3/zyy/KingdeeClues.vue"),
    meta: {
      isThreeNav: true,
      title: "（公海）金蝶投放线索",
      activeUrl: "/ptClueOpenSea",
    },
  },
  {
    //线索公海的捷融投放线索
    path: "clue/jieRonClues",
    name: "jieRonClues",
    component: () => import("@v/clueV3/zyy/jieRonClues.vue"),
    meta: {
      isThreeNav: true,
      title: "（公海）捷融投放线索",
      activeUrl: "/ptClueOpenSea",
    },
  },
  {
    //线索公海的自然线索
    path: "clue/naturalClues",
    name: "naturalClues",
    component: () => import("@v/clueV3/zyy/naturalClues.vue"),
    meta: {
      isThreeNav: true,
      title: "（公海）自然线索",
      activeUrl: "/ptClueOpenSea",
    },
  },
  {
    //（V3）销售线索
    path: "clue/SalesClue",
    name: "ptSalesClue",
    component: () => import("@v/clueV3/zyy/salesClues.vue"),
    meta: {
      title: "销售线索",
      activeUrl: "/clue/SalesClue",
    },
  },
  {
    //（V3）内部消化
    path: "clue/CInternal",
    name: "CInternal",
    component: () => import("@v/clueV3/zyy/CInternal.vue"),
    meta: {
      title: "内部消化",
      activeUrl: "/clue/CInternal",
    },
  },
  // 商机管理
  {
    path: "business/BPlatform",
    name: "BPlatform",
    component: () => import("@v/business/zyy/BPlatform.vue"),
    meta: {
      title: "平台分发",
      activeUrl: "/business/BPlatform",
    },
  },
  {
    path: "business/BInternal",
    name: "BInternal",
    component: () => import("@v/business/zyy/BInternal.vue"),
    meta: {
      title: "内部消化",
      activeUrl: "/business/BInternal",
    },
  },
  // // 订单管理
  // {
  //   path: 'order/OPlatform',
  //   name: 'OPlatform',
  //   component: () => import('@v/order/zyy/OPlatform.vue'),
  //   meta: {
  //     title: '平台分发',
  //     activeUrl: '/order/OPlatform',
  //   },
  // },
  // {
  //   path: 'order/OInternal',
  //   name: 'OInternal',
  //   component: () => import('@v/order/zyy/OInternal.vue'),
  //   meta: {
  //     title: '内部消化',
  //     activeUrl: '/order/OInternal',
  //   },
  // },
  // 管理中心
  {
    path: "management/grant",
    name: "grant",
    component: () => import("@v/management/zyy/grant.vue"),
    meta: {
      isThreeNav: true,
      title: "已开通机构",
      activeUrl: "/orgManagement",
    },
  },
  {
    path: "management/unauthorized",
    name: "unauthorized",
    component: () => import("@v/management/zyy/unauthorized.vue"),
    meta: {
      isThreeNav: true,
      title: "未开通机构",
      activeUrl: "/orgManagement",
    },
  },
  {
    path: "management/staffList",
    name: "staffList",
    component: () => import("@v/management/zyy/staffList.vue"),
    meta: {
      title: "员工列表",
      activeUrl: "/orgManagement",
    },
  },
  {
    path: "management/staff",
    name: "staffManagement",
    component: () => import("@v/management/zyy/staffManagement.vue"),
    meta: {
      isThreeNav: true,
      title: "员工管理",
      activeUrl: "/staffManagement",
    },
  },
  {
    path: "management/department",
    name: "department",
    component: () => import("@v/management/agency/staffManagement/cxqw/cxqwDepartment"),
    meta: {
      isThreeNav: true,
      title: "部门管理",
      activeUrl: "/staffManagement",
    },
  },
  {
    path: "management/inherit",
    name: "inherit",
    component: () => import("@v/management/zyy/inherit.vue"),
    meta: {
      isThreeNav: true,
      title: "离职继承",
      activeUrl: "/departureTransfer",
    },
  },
  {
    path: "management/allocateInfo",
    name: "allocateInfo",
    component: () => import("@v/management/zyy/allocateInfo.vue"),
    meta: {
      isThreeNav: true,
      title: "已分配线索",
      activeUrl: "/departureTransfer",
    },
  },
  {
    path: "management/allocatePartner",
    name: "allocatePartner",
    component: () => import("@v/management/zyy/allocatePartner.vue"),
    meta: {
      isThreeNav: true,
      title: "已分配好友",
      activeUrl: "/departureTransfer",
    },
  },
  {
    path: "management/incumbency",
    name: "incumbency",
    component: () => import("@v/management/zyy/inherit.vue"),
    meta: {
      isThreeNav: true,
      title: "在职继承",
      activeUrl: "/incumbencyTransfer",
    },
  },
  {
    path: "management/INallocateInfo",
    name: "INallocateInfo",
    component: () => import("@v/management/zyy/allocateInfo.vue"),
    meta: {
      isThreeNav: true,
      title: "已分配商机/订单",
      activeUrl: "/incumbencyTransfer",
    },
  },
  {
    path: "management/INallocatePartner",
    name: "INallocatePartner",
    component: () => import("@v/management/zyy/allocatePartner.vue"),
    meta: {
      isThreeNav: true,
      title: "已分配好友",
      activeUrl: "/incumbencyTransfer",
    },
  },
  {
    path: "management/channel",
    name: "channel",
    component: () => import("@v/management/zyy/channel.vue"),
    meta: {
      isThreeNav: true,
      title: "管理",
      activeUrl: "/channelManagement",
    },
  },
  {
    path: "management/call",
    name: "call",
    component: () => import("@v/management/zyy/call.vue"),
    meta: {
      isThreeNav: true,
      title: "(平台)通话记录",
      activeUrl: "/callManagement",
    },
  },
  {
    path: "management/calling",
    name: "calling",
    component: () => import("@v/management/agency/calling/calling.vue"),
    meta: {
      isThreeNav: true,
      title: "(居间)通话记录",
      activeUrl: "/callManagement",
    },
  },
  {
    path: "management/message",
    name: "message",
    component: () => import("@v/management/zyy/message.vue"),
    meta: {
      isThreeNav: true,
      title: "(平台)短信记录",
      activeUrl: "/messageManagement",
    },
  },
  {
    path: "management/msg",
    name: "msg",
    component: () => import("@v/management/agency/msg/msg.vue"),
    meta: {
      isThreeNav: true,
      title: "（居间）短信记录",
      activeUrl: "/messageManagement",
    },
  },
  {
    path: "management/statisticalState",
    name: "statisticalState",
    component: () => import("@v/management/zyy/statisticalStatement.vue"),
    meta: {
      isThreeNav: true,
      title: "统计报表",
      activeUrl: "/statisticalState",
    },
  },
  {
    path: "management/launch",
    name: "launch",
    component: () => import("@v/management/zyy/launch.vue"),
    meta: {
      isThreeNav: true,
      title: "投放管理",
      activeUrl: "/launchManagement",
    },
  },
  {
    path: "management/land",
    name: "land",
    component: () => import("@v/management/zyy/land.vue"),
    meta: {
      isThreeNav: true,
      title: "投放落地页",
      activeUrl: "/launchManagement",
    },
  },
  {
    path: "zyy/management/awaitAttribution",
    name: "awaitAttribution",
    component: () => import("@v/management/zyy/awaitAttribution.vue"),
    meta: {
      isThreeNav: true,
      title: "待推送池",
      activeUrl: "/attribution",
    },
  },
  {
    path: "zyy/management/partake",
    name: "partake",
    component: () => import("@v/management/zyy/partake.vue"),
    meta: {
      isThreeNav: true,
      title: "参与投放公司",
      activeUrl: "/partakeManagement",
    },
  },
  {
    path: "management/log",
    name: "log",
    component: () => import("@v/management/zyy/log.vue"),
    meta: {
      isThreeNav: true,
      title: "归属日志",
      activeUrl: "/partakeManagement",
    },
  },
  {
    path: "management/page",
    name: "pageConfig",
    component: () => import("@v/management/zyy/pageConfig.vue"),
    meta: {
      isThreeNav: true,
      title: "房抵落地页可选城市配置",
      activeUrl: "/launchManagement",
    },
  },
  {
    path: "management/pagecar",
    name: "pageConfigCar",
    component: () => import("@v/management/zyy/pageConfigCar.vue"),
    meta: {
      isThreeNav: true,
      title: "信贷落地页可选城市配置",
      activeUrl: "/launchManagement",
    },
  },
  {
    path: "management/agentManagement",
    name: "agent",
    component: () => import("@v/management/zyy/agent.vue"),
    meta: {
      isThreeNav: true,
      title: "代理商管理",
      activeUrl: "/launchManagement",
    },
  },
  {
    path: "management/citygroup",
    name: "citygroup",
    component: () => import("@v/management/zyy/citygroup.vue"),
    meta: {
      isThreeNav: true,
      title: "城市组",
      activeUrl: "/launchManagement",
    },
  },
  // 线索、商机、订单详情
  {
    path: "baseDetails/baseInfo",
    name: "baseInfo",
    component: () => import("@v/baseDetails/base.vue"),
    meta: {
      details: true,
    },
  },
  // 工作看板
  {
    path: "/board",
    name: "board",
    component: () => import("@v/board/base.vue"),
    meta: {
      title: "工作看板",
    },
  },
  // 投放清单
  {
    path: "launch/Zclues",
    name: "launchClues",
    component: () => import("@v/launch/clue/ZClue.vue"),
    meta: {
      isThreeNav: true,
      title: "线索",
      activeUrl: "/launchDetail",
    },
  },
  // 独立投放
  {
    path: "launch/independent",
    name: "independent",
    component: () => import("@v/launch/clue/independent.vue"),
    meta: {
      isThreeNav: true,
      title: "独立投放",
      activeUrl: "/launchDetail",
    },
  },
  // 综合流量清单
  {
    path: "launch/synthesis",
    name: "synthesis",
    component: () => import("@v/launch/clue/synthesis.vue"),
    meta: {
      isThreeNav: true,
      title: "综合流量清单",
      activeUrl: "/launchDetail",
    },
  },
   // 其他数据 其他平台如哈罗查看列表页 仅有可读权限 无任何操作权限
   {
    path: "launch/otherPlatformData",
    name: "otherPlatformData",
    component: () => import("@v/launch/clue/otherPlatformData.vue"),
    meta: {
      isThreeNav: true,
      title: "其他数据",
      activeUrl: "/launchDetail",
    },
  },
  // API推送日志
  {
    path: "launch/apiPushLogList",
    name: "apiPushLogList",
    component: () => import("@v/launch/clue/apiPushLogList.vue"),
    meta: {
      isThreeNav: true,
      title: "API推送日志",
      activeUrl: "/launchDetail",
    },
  },
  {
    path: "launch/IBusiness",
    name: "launchIBusiness",
    component: () => import("@v/launch/business/IBusiness.vue"),
    meta: {
      isThreeNav: true,
      title: "商机",
      activeUrl: "/independent",
    },
  },
  {
    path: "launch/IOrder",
    name: "launchIOrder",
    component: () => import("@v/launch/order/IOrder.vue"),
    meta: {
      isThreeNav: true,
      title: "订单",
      activeUrl: "/independent",
    },
  },
  {
    path: "launch/Zbusiness",
    name: "launchBusiness",
    component: () => import("@v/launch/business/ZBusiness.vue"),
    meta: {
      isThreeNav: true,
      title: "商机",
      activeUrl: "/launchDetail",
    },
  },
  {
    path: "launch/Zorders",
    name: "launchOrders",
    component: () => import("@v/launch/order/ZOrder.vue"),
    meta: {
      isThreeNav: true,
      title: "订单",
      activeUrl: "/launchDetail",
    },
  },
  // 批量导入 导入历史
  {
    path: "upload/base",
    name: "upload",
    component: () => import("@v/upload/base.vue"),
    meta: {
      isThreeNav: true,
      title: "批量导入",
      activeUrl: "/upload",
    },
  },
  {
    path: "upload/history",
    name: "upload",
    component: () => import("@v/upload/history.vue"),
    meta: {
      isThreeNav: true,
      title: "批量导入",
      activeUrl: "/upload",
    },
  },
  // 表单组件
  {
    path: "management/formCom",
    name: "formCom",
    component: () => import("@v/management/creatForm/formCom.vue"),
    meta: {
      isThreeNav: true,
      title: "表单组件",
      activeUrl: "/launchManagement",
    },
  },
  // BI 统计报表
  {
    path: "biStatisForm/statisticAnaStore",
    name: "statisticAnaStore",
    component: () => import("@v/biState/zyy/statisticAnaStore.vue"),
    meta: {
      isThreeNav: true,
      title: "客户统计分析-门店统计",
      activeUrl: "/statisticAna",
    },
  },
  {
    path: "biStatisForm/followUpAnaStore",
    name: "followUpAnaStore",
    component: () => import("@v/biState/zyy/followUpAnaStore.vue"),
    meta: {
      isThreeNav: true,
      title: "客户跟进分析-门店统计",
      activeUrl: "/followUpAna",
    },
  },
];
export default zyy;
